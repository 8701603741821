<!-- eslint-disable no-useless-escape -->
<template>
    <div class="card">
        <!-- <Toast />
      <ConfirmDialog group="dialog" /> -->
        <form>
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>어드민 계정아이디</h5>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">아이디 (이메일 가입계정)</label>
                            <InputText v-model="state.email" type="text" placeholder="이메일 주소 형태 입력" id="title2"></InputText>
                            <!-- <div class="text-red">{{ error.name }}</div> -->
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">비밀번호</label>
                            <Password v-model="state.password" placeholder="비밀번호 입력" :feedback="false" toggle-mask>
                                <template #header>
                                    <Divider />
                                    <p>비밀번호는 8~16자로 대문자, 소문자, 숫자 및 특수 문자들 각하 나 이상 포함하며 설정하셔야 합니다</p>
                                </template>
                            </Password>
                            <small class="p-mt-2" style="color: #17a2b8">비밀번호는 8~16자로 대문자, 소문자, 숫자 및 특수 문자들 각하 나 이상 포함하며 설정하셔야 합니다</small>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">비밀번호 확인</label>
                            <Password id="password" placeholder="비밀번호 확인" v-model="state.confirmPass" :feedback="false" toggle-mask />
                            <!-- <div class="text-red">{{ error.name }}</div> -->
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">품의번호</label>
                            <InputText type="text" placeholder="품의번호" v-model="state.product_number"></InputText>
                            <!-- <div class="text-red">{{ error.name }}</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-end p-ai-center">
                <div>
                    <Button @click="submit" :loading="isLoading" label="승인 요청" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2"></Button>
                </div>
            </div>
        </form>
        <Dialog v-model:visible="displayModalOne" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true" class="hide-cross">
            <div class="p-field p-fluid">
                <p class="p-text-center">{{ message }}</p>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-center">
                    <Button label="확인" @click="dialogAction(parameterVal)" size="large" class="p-button-primary" />
                </div>
            </template>
        </Dialog>
    </div>
</template>
<script setup>
  import { reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import UserService from '../service/API/SignUpService';
  const router = useRouter();
  const displayModalOne = ref(false);
  const isLoading = ref(false);
  const message = ref('');
  const parameterVal = ref('');
  const state = reactive({
      email: '',
      password: '',
      confirmPass: '',
      product_number: '',
  });
  const dialogAction = async (issue) => {
      if (issue == 'missing') {
          displayModalOne.value = false;
          isLoading.value = false;
      } else if (issue == 'success') {
        state.password = '';
        router.push('/login')
      } else if (issue == 'format') {
          state.password = '';
          state.confirmPass = '';
          displayModalOne.value = false;
          isLoading.value = false;
      } else if (issue == 'notmatch') {
          state.password = '';
          state.confirmPass = '';
          displayModalOne.value = false;
          isLoading.value = false;
      }else if(issue == 'same'){
        state.email = '';
        displayModalOne.value = false;
        isLoading.value = false;
      }
  };
  const isPasswordValid = (password) => {
      // Check length
      if (password.length < 8 || password.length > 16) {
          console.log('length check letter');
          return false;
      }

      // Check for at least one uppercase letter
      else if (!/[A-Z]/.test(password)) {
          console.log('uppercase letter');
          return false;
      }

      // Check for at least one lowercase letter
      else if (!/[a-z]/.test(password)) {
          console.log('lowercase letter');
          return false;
      }

      // Check for at least one number
      else if (!/\d/.test(password)) {
          console.log('one number');
          return false;
      }

      // Check for at least one special character
      // eslint-disable-next-line no-useless-escape
      else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
          console.log('special character');
          return false;
      } else {
          // If all conditions are met, the password is valid
          return true;
      }
  };
  const signUp = async () => {
    isLoading.value = true
      let credentials = {
          email: state.email,
          password: state.password,
          product_no: state.product_number,
      };
      try{
        let res = await UserService.signUpUser(credentials)
        if(res.status == 200){
            console.log(res)
            isLoading.value = false
        }
      }catch(err){
        console.log(err)
        message.value = err.response.data.error_message
        parameterVal.value = 'same';
        isLoading.value = false
      }
  };

  const submit = async () => {
      message.value = '';
      if (!state.email || !state.password || !state.confirmPass || !state.product_number) {
          parameterVal.value = 'missing';
          message.value = '입력되지 않은 항목이 있습니다.';
          displayModalOne.value = true;
      } else {
          if (isPasswordValid(state.password) == false) {
              parameterVal.value = 'format';
              message.value = '비밀번호 가이드에 맞게 입력해주세요. 8~16자로 대문자,소문자, 숫자 및특수 문자 1개 이상 포함';
              displayModalOne.value = true;
          } else if (state.password !== state.confirmPass) {
              parameterVal.value = 'notmatch';
              message.value = '입력하신 비밀번호가 일치하지 않습니다.';
              displayModalOne.value = true;
          } else {
              parameterVal.value = 'success';
              message.value = '계정 가입 승인 요청 되었습니다.승인 완료 시점부터 로그인 가능합니다.';
              await signUp()
              displayModalOne.value = true;
          }
      }
  };
  isLoading.value = false;
</script>
<style scoped>
  .p-dialog-header {
      display: none !important;
  }
</style>