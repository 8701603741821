// import axios from 'axios';
import { hanatourApi } from '../../main.js';
export default {
  async signUpUser(data) {
    return await hanatourApi.post('admin/user/signup', data)
},
async getSignUpUserList(query) {
  return await hanatourApi.get('admin/user/user-approval-request-list', {params:query})
},
async sendAccess(access){
  return await hanatourApi.post('admin/user/change-approval-request', access)
}
}